import Vue from 'vue';
import Vuex from 'vuex';
import city from '../store/modules/city';
import filter from '../store/modules/filter';

Vue.use(Vuex);

export default new Vuex.Store({
  //plugins: {},
  modules: {
    filter: {
      namespaced: true,
      ...filter
    },
    city: {
      namespaced: true,
      ...city
    }
  }
});
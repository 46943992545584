<template>
  <div class="howto">
    <b-container>
      <div 
        class="back-link"
        v-on:click="navBack()"
      >
        <BIconArrowLeftShort /> Go back to the map
      </div>
      <div class="title">
        <h1>How to use the Startup Cities Map</h1>
      </div>
      <div class="content-text">
        <p>Aliquam ac lacinia mi. Suspendisse ac feugiat ligula. Ut nibh nibh, iaculis nec ullamcorper vehicula, cursus in lectus. Nullam porta semper rutrum. Pellentesque convallis arcu mauris, a aliquet risus malesuada non. Aenean rhoncus velit sit amet massa tincidunt, ac fermentum massa cursus. Curabitur dapibus lorem dui, vitae sagittis massa auctor dapibus. Integer blandit auctor vulputate. Phasellus eget neque tempor, tempus tortor at, dapibus ante. Mauris sed rhoncus neque. Quisque eu justo ut lectus laoreet porta. Nullam consequat metus ut dolor congue varius. Phasellus sodales iaculis odio, tincidunt placerat nibh sollicitudin sed. Proin molestie massa nisi, eu congue velit dapibus vel. Nam luctus ex at auctor laoreet.</p>
      </div>
      <div class="map-image">
        <b-img :src="image"></b-img>
      </div>
      <div class="instruction-title">
        <h2>Main Contrl functions</h2>
      </div>
      <div class="content-text">
        <p>Aliquam ac lacinia mi. Suspendisse ac feugiat ligula. Ut nibh nibh, iaculis nec ullamcorper vehicula, cursus in lectus. Nullam porta semper rutrum. Pellentesque convallis arcu mauris, a aliquet risus malesuada non. Aenean rhoncus velit sit amet massa tincidunt, ac fermentum massa cursus. Curabitur dapibus lorem dui, vitae sagittis massa auctor dapibus. Integer blandit auctor vulputate. Phasellus eget neque tempor, tempus tortor at, dapibus ante. Mauris sed rhoncus neque. Quisque eu justo ut lectus laoreet porta. Nullam consequat metus ut dolor congue varius. Phasellus sodales iaculis odio, tincidunt placerat nibh sollicitudin sed. Proin molestie massa nisi, eu congue velit dapibus vel. Nam luctus ex at auctor laoreet.</p>
      </div>
      <div class="instruction-title">
        <h2>Color legends</h2>
      </div>
      <div class="map-features">
        <FeatureType
          v-for="(item, key) in features"
          :key="key"
          :name="item.name"
          :featureColor="item.color"
        ></FeatureType>
      </div>
      <div class="content-text">
        <p>Aliquam ac lacinia mi. Suspendisse ac feugiat ligula. Ut nibh nibh, iaculis nec ullamcorper vehicula, cursus in lectus. Nullam porta semper rutrum. Pellentesque convallis arcu mauris, a aliquet risus malesuada non. Aenean rhoncus velit sit amet massa tincidunt, ac fermentum massa cursus. Curabitur dapibus lorem dui, vitae sagittis massa auctor dapibus. Integer blandit auctor vulputate. Phasellus eget neque tempor, tempus tortor at, dapibus ante. Mauris sed rhoncus neque. Quisque eu justo ut lectus laoreet porta. Nullam consequat metus ut dolor congue varius. Phasellus sodales iaculis odio, tincidunt placerat nibh sollicitudin sed. Proin molestie massa nisi, eu congue velit dapibus vel. Nam luctus ex at auctor laoreet.</p>
      </div>
      <div class="map-image">
        <b-img :src="image2"></b-img>
      </div>
    </b-container>
  </div>
</template>
<script>
import image from '../assets/scm-square.png';
import image2 from '../assets/scm-wide.png'; 
import { BIconArrowLeftShort}  from 'bootstrap-vue';
import FeatureType from '../components/howto/FeatureType.vue';
export default {
  components: {
    BIconArrowLeftShort: BIconArrowLeftShort,
    FeatureType: FeatureType
  },
  data() {
    return {
      image: image,
      image2: image2,
      features: [
        {
          name: 'San Francisco',
          color: '#7DD5F0'
        },
        {
          name: 'Miami',
          color: '#D29AF5'
        },
        {
          name: 'Culdesac',
          color: '#81D17A'
        }
      ]
    }
  },
  methods: {
    navBack() {
      this.$router.push('/map');
    }
  }
}
</script>
<style lang="scss" scopped>
  .howto {
    background-color: #f7f7f7;
    font-family: Cabin, Medium;
    .back-link, .title, .content-text,  .instruction-title, .map-features {
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .back-link {
      color: #9C9C9C;
      font-size: 12px;
      svg {
        color: #2B72D8;
        transform: scale(1.5);
      }
    }
    .back-link:hover {
      color: #2B72D8;
      cursor: pointer;
    }
    .title {
      color: #2B72D8;
      font-size: 31px;
      padding-left: 1rem;
      width: 70%;
      height: auto;
      line-height: 115%;
    }
    .instruction-title {
      color: #2B72D8;
    }
    .content-text {
      color: #9C9C9C;
      font-size: 12px;
      padding-right: 1rem;
    }
    .map-features {
      display: flex;
    }
    .map-image {
      img {
        width: 100%;
        object-fit: fill;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
</style>
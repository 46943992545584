const state = {
  cityTypes: [
    {
      cityType: 'Type 1',
      cityTypeId: 'SC1',
      name: 'San Francisco',
      selected: true,
      description: 'A city where startups are based',
      introText: "Here's a small text describing Cities that house many startup companies and why it is here.",
      imgUrl: 'https://cdn.travelpulse.com/images/d3a9edf4-a957-df11-b491-006073e71405/e864929d-127a-4caf-9a15-7f9cd45160f0/630x355.jpg'
    },
    {
      cityType: 'Type 2',
      cityTypeId: 'SC2',
      name: 'Miami',
      selected: true,
      description: 'A city that acts like a startup',
      introText: "Here's a small text describing Cities that act like a startup in themselves and why it is here.",
      imgUrl: 'https://www.outfrontmedia.com/-/media/images/ofm/markets/miami/miami-hero.jpg'
    },
    {
      cityType: 'Type 3',
      cityTypeId: 'SC3',
      name: 'Culdesac',
      selected: true,
      description: 'A city that is a startup',
      introText: "Here's a small text describing Cities that are startups in and of themselves and why they are here.",
      imgUrl: 'https://culdesac.com/static/226807d17289b30a343f73eeab01cd63/08045/promenade.jpg'
    }
  ],
  keyword: null,
  toggle: false
}

const getters = {
  cityTypes: state => state.cityTypes,
  cityType: (state) => (key) => {
    state.cityTypes.filter(e => e.cityType === key)[0]
  },
  keyword: state => state.keyword,
  toggle: state => state.toggle
}

const actions = {
  async setCityTypes({ commit }, payload) {
    commit('SET_CITY_TYPES', payload);
  },
  async setKeyWord({ commit }, payload) {
    commit('SET_KEYWORD', payload);
  },
  async setFilterCtlToggle({ commit }, payload) {
    commit('SET_TOGGLE', payload);
  }
}

const mutations = {
  ['SET_CITY_TYPES']( state, payload ) {
    //console.log(`[SET_CITY_TYPES] : state.cityTypes[${payload.cityType}].selected = true`);
    const cityType = state.cityTypes.filter(
      e => e.cityType === payload.cityType
    )[0];
    cityType.selected = payload.value;
  },
  ['SET_KEYWORD']( state, payload ) {
    //console.log(`[SET_KEYWORD] : state.keyword = ${payload.keyword}`);
    state.keyword = payload.keyword;
  },
  'SET_TOGGLE'( state, payload) {
    //console.log(`SET_TOGGLE : state.toggle = ${payload}`);
    state.toggle = payload;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
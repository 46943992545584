const state = {
  name: null,
  country: null,
  cityType: null,
  blurb: null,
  imgUrl: null,
  set: null,
  action: null,
  close: null,
  webLink: null,
  wikiLink: null
}

const getters = {
  set: state => state.set,
  name: state => state.name,
  country: state => state.country,
  blurb: state => state.blurb,
  imgUrl: state => state.imgUrl,
  cityType: state => state.cityType,
  action: state => state.action,
  close: state => state.close,
  webLink: state => state.webLink,
  wikiLink: state => state.wikiLink
}

const actions = {
  setCity({ commit }, payload) {
    commit('SET_CITY', payload);
  }
}

const mutations = {
  ['SET_CITY']( state, payload ) {
    //console.log(`[SET_CITY] state.set:`, payload);
    const { name, cityType, blurb, imgUrl, set, action, close, country, webLink, wikiLink} = payload;
    state.name = name;
    state.country = country;
    state.cityType = cityType;
    state.blurb = blurb;
    state.imgUrl = imgUrl;
    state.set = set;
    state.action = action;
    state.close = close;
    state.webLink = webLink,
    state.wikiLink = wikiLink
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
<template>
  <div class="error-component">
    <b-container>
      <b-row>
        <b-col>
          <div class="img">
            <b-img :src="notFound"></b-img>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="header">
          <h1>Oops!</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="result">
          <h2>Technical difficulties</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="explain">
          <p>Sorry, the browser and device you are using does not support rendering this type of map. Plese visit us on desktop, laptop, another mobile device or change the browser.</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import notFound from "../assets/error-404-pin.svg";
export default {
  data() {
    return {
      notFound: notFound
    }
  },
  mounted() {
    this.$gtm.trackView("Error", "/error");
  }
}
</script>
<style lang="scss" scoped>
.error-component {
  background-color: rgba(0, 0, 0, 0);
  font-family: 'Cabin', sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;

  .container {
    div.row {
      padding-top: 1rem;
      padding-bottom: 1rem;
      .header {
        color: #2A72D8;
        h1 {
          font-size: 4.5rem;
        }
      }
      .result {
        color: #2A72D8;
      }
      .explain {
        color: #272727;
      }
      .call-to-action {
        label {
          color: #2A72D8;
          font-size: 20px;
        }
      }
      div.col {
        text-align: center;
      }
    }
  }
}
</style>
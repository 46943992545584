<template>
  <div class="preview-card">
    <div 
      class="close-preview"
    >
      <b-icon icon="x-circle" @click="close()"></b-icon>
    </div>
    <b-container>
      <b-row class="city-name">
        <b-col>
          {{ name }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-img class="city-img" :src="imgUrl ? imgUrl : placeHolderImg"></b-img>
        </b-col>
      </b-row>
      <b-row class="city-blurb">
        <b-col>
          {{ conformBlurb(blurb) }}
        </b-col>
      </b-row>
      <b-row class="city-type-row">
        <b-col
          v-if="cityType === 'SC1'"
        >
          <b-img class="city-type-img" :src="cityLogo"></b-img>
          Type 1: A city that attracts startups
        </b-col>
        <b-col
          v-if="cityType === 'SC2'"
        >
          <b-img class="city-type-img" :src="cityLogo"></b-img>
          Type 2: A city that acts like a startup
        </b-col>
        <b-col
          v-if="cityType === 'SC3'"
        >
          <b-img class="city-type-img" :src="cityLogo"></b-img>
          Type 3: A city that is a startup
        </b-col>
        <b-col
          v-if="cityType === 'combo'"
        >
          <b-img class="city-type-img" :src="cityLogo"></b-img>
          Type 1 & 2 : A city that attracts startups and acts like one
        </b-col>
      </b-row>
      <b-row class="city-link-row">
        <b-col>
          <div v-if="cityType == 'SC1' || cityType == 'SC2' || cityType == 'combo'">
            <b-img :src="wikiLogo"></b-img>
            <a :href="wikiLink" target="__blank">Wikipedia</a>
          </div>
          <div v-else>
            <b-img :src="mouseLogo"></b-img>
            <a :href="webLink" target="__blank">Site</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import placeHolderImg from '../../assets/CityDefault.png';
import cityLogo from '../../assets/MiniCityLogo.svg';
import mouseLogo from "../../assets/Icon-feather-mouse-pointer.svg";
import wikiLogo from "../../assets/wikipedia-logo.svg";

export default {
  props: {
    // close: {
    //   type: Function,
    //   required: true
    // }
  },
  components: {},
  computed: {
    ...mapGetters({
      set: "city/set",
      name: "city/name",
      blurb: "city/blurb",
      imgUrl: "city/imgUrl",
      cityType: "city/cityType",
      webLink: "city/webLink",
      wikiLink: "city/wikiLink"
    })
  },
  data() {
    return {
      placeHolderImg: placeHolderImg,
      cityLogo: cityLogo,
      mouseLogo: mouseLogo,
      wikiLogo: wikiLogo
    }
  },
  methods: {
    close() {
      const el = document.getElementById('preview-card');
      el.classList.add('slide-out-right');
      el.classList.add('slide-in-right');
    },
    conformBlurb(text) {
      let newText = text.slice(1, -1);
      return newText.replace(/''/gm,`'`);
    }
  }
}
</script>
<style lang="scss">
.preview-card {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000019;
  font-family: Cabin, Regular;
  .close-preview {
    color: #2A72D8;
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 0.125rem;
    font-size: 2rem;
    z-index: 1;
  }
  .container {
    padding: 1rem 1rem 1rem 1rem;
    .city-name {
      color: #2A72D8;
      font-size: 2rem;
      padding-bottom: 1rem;
      @media only screen and (max-height: 700px) {
        font-size: 1.5rem;
        max-height: 125px;
        width: auto;
      }
    }
    .city-img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 275px;
      width: 100%;
      margin-bottom: 1.5rem;
      @media only screen and (max-height: 700px) {
        max-height: 155px;
        width: auto;
      }
    }
    .city-blurb {
      color: #727272;
      font-size: 0.9rem;
      @media only screen and (max-height: 700px) {
        font-size: 0.7rem;
      }
    }
    div.row.city-type-row,
    div.row.city-link-row {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 0.75rem;
      font-size: 0.9rem;
      div.col {
        padding-left: unset;
        border-bottom: 1px rgba(0, 0, 0, 0.25) solid;
        padding-bottom: 0.5rem;
      }
      @media only screen and (max-height: 700px) {
        font-size: 0.7rem;
      }
    }
    div.row.city-type-row {
      div.col {
        color: #2A72D8;
        img {
          height: 1rem;
          width: auto;
          margin-right: 1rem;
        }
      }
    }
    div.row.city-link-row {
      div.col {
        div {
          img {
            height: 1rem;
            width: auto;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
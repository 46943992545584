<template>
  <div class="right-menu">
    <div class="above-items"></div>
    <div class="items">
      <b-row class="row-item" @click="navAbout('')">
        <b-col cols="10">
          <div class="menu-item">
            <p>About the Startup Cities Map</p>
          </div>
        </b-col>
        <b-col cols="2">
          <b-icon icon="arrow-right"></b-icon>
        </b-col>
      </b-row>
      <b-row class="row-item" @click="navAbout('methodology')">
        <b-col cols="10">
          <div class="menu-item">
            <p>Methodology</p>
          </div>
        </b-col>
        <b-col cols="2">
          <b-icon icon="arrow-right"></b-icon>
        </b-col>
      </b-row>
      <b-row class="row-item">
        <b-col cols="10">
          <div class="menu-item">
            <!-- <p>Submit New Projects</p> -->
            <a href="https://airtable.com/shrXdbRpBcLS01WD9" target="__blank">Submit New Projects</a>
          </div>
        </b-col>
        <b-col cols="2">
          <b-icon icon="arrow-right"></b-icon>
        </b-col>
      </b-row>
      <b-row class="row-item" @click="navAbout('how-to-use-this-map')">
        <b-col cols="10">
          <div class="menu-item">
            <p>How to use this Map</p>
          </div>
        </b-col>
        <b-col cols="2">
          <b-icon icon="arrow-right"></b-icon>
        </b-col>
      </b-row>
      <b-row class="row-item" @click="navAbout('about-the-adrianople-group')">
        <b-col cols="10">
          <div class="menu-item">
            <p>About the Adrianople Group</p>
          </div>
        </b-col>
        <b-col cols="2">
          <b-icon icon="arrow-right"></b-icon>
        </b-col>
      </b-row>
      <!-- <b-row class="row-item" @click="navHowTo">
        <b-col cols="10">
          <div class="menu-item">
            <p>How to use it</p>
          </div>
        </b-col>
        <b-col cols="2">
          <BIconPlus />
        </b-col>
      </b-row> -->
    </div>
    <div class="below-items"></div>
    <div class="attribution">
      Created by the Adrianople Group
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  methods: {
    navAbout (id) {
      if (id === '') {
        this.$router.push({name: "About", path: "/about"})
      } else {
        //this.$router.push(`/about#${id}`);
        this.$router.push({name: "About", path: "/about", hash: `#${id}`})
      }
      this.$root.$emit('close-menu');
    },
    navHowTo () {
      this.$router.push('/howto');
      this.$root.$emit('close-menu');
    },
  }
}
</script>
<style lang="scss" scoped>
.right-menu {
  position: absolute;
  top: 10vh;
  width: 20rem;
  right: 0rem;
  @media only screen and (max-width: 400px) {
    width: 100vw;
  }
  font-family: Cabin, Medium;
  height: 90vh;
  background-color: #f7f7f7;
  .above-items {
    height: 30vh;
  }
  .row-item {
    //border-radius: 12px;
    border-bottom: 0.5px solid #616161;
    div.col-2 {
      padding-top: 0.50rem;
      svg {
        color: #2B72D8;
      }
    }
  }
  .row-item:hover {
    //background-color: #c9c8c8;
    cursor: pointer;
    color: #2A72D8;
  }
  .items {
    padding-left: 2rem;
    padding-right: 2rem;
    height: 20vh;
    .menu-item {
      color: #616161;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      white-space: nowrap;
      a {
        text-decoration: none;
        color: inherit;
      }
      p {
        padding: unset;
        margin: unset;
      }
    }
  }
  .below-items {
    height: 30vh;
  }
  .attribution {
    padding-left: 2rem;
    padding-right: 1rem;
    white-space: nowrap;
    bottom: 2rem;
    right: 1rem;
    color: #c9c8c8
  }
}
</style>
import cartovl from '@carto/carto-vl';

class CartoLayer {
  constructor(query, params) {
    this.query = query;
    this.params = params;
  }

  async getLayer(layerName) {
    return await this.createLayer(layerName);
  }

  async createLayer(layerName) {
    const viz = new cartovl.Viz(
      this.createVizualExpression()
    )
    const ds = await this.createDataSource();
    //console.log("createLayer ds", ds._client);
    return new cartovl.Layer(
      layerName,
      ds,
      viz
    )
  }

  createVizualExpression() {
    let expression = '';
    if(this.params.color) {
      expression += `color: ${this.params.color}\n`
    }
    if(this.params.strokeColor) {
      expression += `strokeColor: ${this.params.strokeColor}\n`
    }
    if(this.params.strokeWidth) {
      expression += `strokeWidth: (${this.params.strokeWidth} / 10)\n`
    }
    if(this.params.isMobile) {
      // do something
    }
    expression += 'width: ramp(zoomrange([5, 6.5]), [10, 20])\n'
    expression += '@mark: viewportFeatures($name, $city_type, $blurb, $img_url, $latitude, $longitude, $country, $wiki_link, $web_link)\n'
    //expression += '@mark: viewportFeatures($name, $blurb, $img_url, $latitude, $longitude)\n'
    return expression;
  }

  async createDataSource() {
    return await new cartovl.source.SQL(
      this.query, {
        apiKey: process.env.VUE_APP_CARTO_API_KEY,
        user: process.env.VUE_APP_CARTO_USERNAME
      }
    )
  }
}

export {
  CartoLayer
}
<template>
  <div class="main-map">
    <div id="vue-popup-content" ref="cityPopup" :style="{display: cityProps ? 'block' : 'none' }">
      <CityPopup></CityPopup>
    </div>
    
    <div id="map"></div>
    <div 
      v-if="citySet" 
      id="preview-card" 
      class="preview-wrapper" 
    >
      <PreviewCard v-if="!isMobile()"/>
    </div>
    <div 
      v-if="citySet"
      id="mobile-preview-card"
      class="mobile-preview-wrapper card-no-display"
    >
      <MobilePreviewCard v-if="isMobile()"></MobilePreviewCard>
    </div>
    <!-- <div class="filter-toggle">
      <div class="city-types-toggle d-flex justify-content-center">
        <b-icon v-if="toggle" icon="chevron-compact-down" @click="setToggle()"/>
        <b-icon v-if="!toggle" icon="chevron-compact-up" @click="setToggle()"/>
      </div>
    </div> -->
    <div class="filter-wrapper">
      <CityTypesCtl />
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import { mapGetters } from "vuex";
import cartovl from "@carto/carto-vl";
import { CartoLayer } from "../cartoIntegration";
import CityPopup from "../components/MainMap/CityPopup.vue";
import PreviewCard from "../components/MainMap/PreviewCard.vue";
import CityTypesCtl from "../components/MainMap/CityTypesCtl.vue";
import MobilePreviewCard from "../components/MainMap/MobilePreview.vue";

/**
 * We import this here because we are overriding CSS classes with these CSS classes within local scope.
 */
import 'mapbox-gl/dist/mapbox-gl.css';

export default {
  components: {
    MobilePreviewCard,
    CityTypesCtl,
    PreviewCard,
    CityPopup
  },
  computed: {
    ...mapGetters({
      cityTypes: 'filter/cityTypes',
      citySet: 'city/set',
      keyword: 'filter/keyword',
      toggle: 'filter/toggle'
    }),
    filterByCityType1: {
      get() { return this.cityTypes.filter(e => e.cityType === 'Type 1')[0].selected}
    },
    filterByCityType2: {
      get() { return this.cityTypes.filter(e => e.cityType === 'Type 2')[0].selected}
    },
    filterByCityType3: {
      get() { return this.cityTypes.filter(e => e.cityType === 'Type 3')[0].selected}
    },
  },
  watch: {
    'filterByCityType1': function() {
      this.layerMap();
    },
    'filterByCityType2': function() {
      this.layerMap();
    },
    'filterByCityType3': function() {
      this.layerMap();
    },

  },
  data() {
    return {
      map: null,
      SC1: {
        cityType: 'SC1',
        color: '#7DD5F0',
        strokeColor: '#7DD5F0',
        strokeWidth: 1,
        isMobile: false
      },
      SC2: {
        cityType: 'SC2',
        color: '#D29AF5',
        strokeColor: '#D29AF5',
        strokeWidth: 1,
        isMobile: false
      },
      SC3: {
        cityType: 'SC3',
        color: '#81D17A',
        strokeColor: '#81D17A',
        strokeWidth: 1,
        isMobile: false
      },
      scCombo: {
        cityType: 'combo',
        color: '#7DD5F0',
        strokeColor: '#D29AF5',
        strokeWidth: 25,
        isMobile: false
      },
      cityProps: null,
      filterControlActive : null,
      filterControlFresh: true,
      currentMapFeature: null
    }
  },
  methods: {
    setToggle() {
      this.$store.dispatch('filter/setFilterCtlToggle', !this.toggle);
    },
    showMobilePreviewCard() {
      const el = document.getElementById("mobile-preview-card");
      if(el) {
        el.classList.remove("slide-out-bottom");
        el.classList.remove("card-no-display");
        el.classList.add("slide-in-bottom");
      }
    },
    showPreviewCard() {
      // if is not mobile show the preview card
      const el = document.getElementById('preview-card');
      if(el) {
        el.classList.remove('slide-out-right');
        el.classList.add('slide-in-right');
      }
      // else if is mobile show the preview modal
    },
    /**
     * Set a mapbox popup's content to be a vue component and keep the same DOM
     * https://stackoverflow.com/questions/66262471/call-vue-method-from-mapbox-popup
     */
    async addInteractivityToLayer(layer){
      const interactivity = new cartovl.Interactivity(layer);
      interactivity.on('featureClick', async featureEvent => {
        const feature = featureEvent.features[0];
        if (!feature) {return}
        else {

          // HACK
          if (this.currentMapFeature) { return }
          else { this.currentMapFeature = feature; }

          feature.color.blendTo('#2A72D8', 100);

          // instantiate mapbox object to get refference to mapBoxPopup.remove
          const mapBoxPopup = new mapboxgl.Popup({ closeButton: false });

          this.cityProps = {
            name: feature.variables.mark._properties.name,
            country: feature.variables.mark._properties.country,
            blurb: feature.variables.mark._properties.blurb,
            cityType: feature.variables.mark._properties.city_type,
            imgUrl: feature.variables.mark._properties.img_url,
            close: mapBoxPopup.remove,
            webLink: feature.variables.mark._properties.web_link,
            wikiLink: feature.variables.mark._properties.wiki_link,
            set: true,
            action: this.showMobilePreviewCard
          }
          //console.log(this.cityProps);
          await this.$store.dispatch('city/setCity', this.cityProps);
          // will only activate on desktop
          if(!this.isMobile())this.showPreviewCard();
          //else this.showMobilePreviewCard();

          // use map box api to load & render vue component on th map.
          mapBoxPopup.setLngLat([
            feature.variables.mark._properties.longitude,
            feature.variables.mark._properties.latitude
          ]);
          mapBoxPopup.setDOMContent(this.$refs.cityPopup);
          mapBoxPopup.addTo(this.map);
        }
      });

      // reset feature color to cityType color
      interactivity.on('featureClickOut', featureEvent => {
        const feature = featureEvent.features[0];
        if (!feature) return;
        if(feature.variables.mark._properties.city_type == this.SC1.cityType) {
          feature.color.blendTo(this.SC1.color, 100);
        } else if(feature.variables.mark._properties.city_type == this.SC2.cityType) {
          feature.color.blendTo(this.SC2.color, 100);
        } else if (feature.variables.mark._properties.city_type == this.SC3.cityType) {
          feature.color.blendTo(this.SC3.color, 100);
        } else if (feature.variables.mark._properties.city_type == this.scCombo.cityType) {
          feature.color.blendTo(this.scCombo.color, 100);
        }

        // UNHACK
        // Is this neccessary anymore?
        this.currentMapFeature = null;
      });
    },
    async addLayer(params) {
      let query = '';
      if(this.keyword) {
        query = `
          SELECT
            scmc.cartodb_id,
            scmc.the_geom_webmercator,
            scmc.the_geom, 
            scmc.name, 
            scmc.blurb, 
            scmc.img_url, 
            scmc.latitude, 
            scmc.longitude, 
            scmct.id, 
            scmct.label AS city_type,
            scmc.country
          FROM scm_cities scmc
          JOIN scm_cities_citytype_xref scmccxref ON scmc.cartodb_id = scmccxref.cities_fk
          JOIN scm_citytypes scmct ON scmccxref.citytypes_fk = scmct.id
            WHERE scmct.label = '${params.cityType}' 
            AND scmc.name ILIKE '%${this.keyword}%'
            OR scmc.country ILIKE '%${this.country}'`;
      } else {
        if (params.cityType === 'SC1' && this.filterByCityType2) {
          //console.log('layer for SC1 but filter for SC2 also active' ,params)
          query = `
          SELECT
            scmc.cartodb_id,
            scmc.the_geom_webmercator,
            scmc.the_geom, 
            scmc.name, scmc.blurb, 
            scmc.img_url, 
            scmc.latitude, 
            scmc.longitude, 
            scmct.id, 
            scmct.label AS city_type,
            scmc.country,
            scmc.wiki_link,
            scmc.web_link
          FROM scm_cities scmc
          JOIN scm_cities_citytype_xref scmccxref ON scmc.cartodb_id = scmccxref.cities_fk
          JOIN scm_citytypes scmct ON scmccxref.citytypes_fk = scmct.id
          WHERE scmct.label = '${params.cityType}'
          AND scmc.cartodb_id NOT IN (
            SELECT
              scmc.cartodb_id
            FROM scm_cities scmc
            JOIN scm_cities_citytype_xref scmccxref ON scmc.cartodb_id = scmccxref.cities_fk
            JOIN scm_citytypes scmct ON scmccxref.citytypes_fk = scmct.id
            WHERE scmct.label IN('SC1','SC2')
            GROUP BY 
              scmc.cartodb_id
            HAVING COUNT(*) = 2
          )
          `
        } else if (params.cityType === 'SC2' && this.filterByCityType1) {
          //console.log('layer for SC1 but filter for SC2 also active' ,params)
          query = `
          SELECT
            scmc.cartodb_id,
            scmc.the_geom_webmercator,
            scmc.the_geom, 
            scmc.name, scmc.blurb, 
            scmc.img_url, 
            scmc.latitude, 
            scmc.longitude, 
            scmct.id, 
            scmct.label AS city_type,
            scmc.country,
            scmc.wiki_link,
            scmc.web_link
          FROM scm_cities scmc
          JOIN scm_cities_citytype_xref scmccxref ON scmc.cartodb_id = scmccxref.cities_fk
          JOIN scm_citytypes scmct ON scmccxref.citytypes_fk = scmct.id
          WHERE scmct.label = '${params.cityType}'
          AND scmc.cartodb_id NOT IN (
            SELECT
              scmc.cartodb_id
            FROM scm_cities scmc
            JOIN scm_cities_citytype_xref scmccxref ON scmc.cartodb_id = scmccxref.cities_fk
            JOIN scm_citytypes scmct ON scmccxref.citytypes_fk = scmct.id
            WHERE scmct.label IN('SC1','SC2')
            GROUP BY 
              scmc.cartodb_id
            HAVING COUNT(*) = 2
          )
          `
        } else {
          //console.log('normal single layer, no shinanegans', params)
          query = `
          SELECT
            scmc.cartodb_id,
            scmc.the_geom_webmercator,
            scmc.the_geom, 
            scmc.name, scmc.blurb, 
            scmc.img_url, 
            scmc.latitude, 
            scmc.longitude, 
            scmct.id, 
            scmct.label AS city_type,
            scmc.country,
            scmc.wiki_link,
            scmc.web_link
          FROM scm_cities scmc
          JOIN scm_cities_citytype_xref scmccxref ON scmc.cartodb_id = scmccxref.cities_fk
          JOIN scm_citytypes scmct ON scmccxref.citytypes_fk = scmct.id
          WHERE scmct.label = '${params.cityType}'`;
        }
      }
      //console.log(`addLayer for ${params.cityType}`, query);
      const carto = new CartoLayer(query, {
        color: params.color,
        strokeColor: params.strokeColor,
        strokeWidth: params.strokeWidth,
        isMobile: params.isMobile
      });
      const layer = await carto.getLayer(params.cityType);
      layer.addTo(this.map);
      this.addInteractivityToLayer(layer);
    },
    /**
     * add Combo layer via query
     */
    async addComboLayer(params) {
      let query = '';
      if(this.keyword) {
        query = `
          SELECT
            scmc.cartodb_id, 
            scmc.the_geom, 
            scmc.the_geom_webmercator, 
            scmc.name, 
            scmc.blurb, 
            scmc.img_url, 
            scmc.latitude, 
            scmc.longitude,
            'combo' AS city_type,
            scmc.country
          FROM scm_cities scmc
          JOIN scm_cities_citytype_xref scmccxref ON scmc.cartodb_id = scmccxref.cities_fk
          JOIN scm_citytypes scmct ON scmccxref.citytypes_fk = scmct.id
            WHERE scmct.label IN('SC1','SC2') 
            AND scmc.name ILIKE '%${this.keyword}%'
          GROUP BY 
            scmc.cartodb_id, 
            scmc.the_geom, 
            scmc.the_geom_webmercator, 
            scmc.name, 
            scmc.blurb, 
            scmc.img_url, 
            scmc.latitude, 
            scmc.longitude, 
            scmc.country
          HAVING COUNT(*) = 2`;
      } else {
        query = `
          SELECT
            scmc.cartodb_id, 
            scmc.the_geom, 
            scmc.the_geom_webmercator, 
            scmc.name, 
            scmc.blurb, 
            scmc.img_url, 
            scmc.latitude, 
            scmc.longitude,
            'combo' AS city_type,
            scmc.country,
            scmc.wiki_link,
            scmc.web_link
          FROM scm_cities_citytype_xref scmccxref
          JOIN scm_citytypes scmct ON scmccxref.citytypes_fk = scmct.id
          JOIN scm_cities scmc ON scmccxref.cities_fk = scmc.cartodb_id
          WHERE scmct.label IN('SC1','SC2')
          GROUP BY 
            scmc.cartodb_id, 
            scmc.the_geom, 
            scmc.the_geom_webmercator, 
            scmc.name, 
            scmc.blurb, 
            scmc.img_url, 
            scmc.latitude, 
            scmc.longitude, 
            scmc.country,
            scmc.wiki_link,
            scmc.web_link
          HAVING COUNT(*) = 2`;
      }
      const carto = new CartoLayer(query, {
        color: params.color,
        strokeColor: params.strokeColor,
        strokeWidth: params.strokeWidth,
        isMobile: params.isMobile
      });
      const layer = await carto.getLayer(params.cityType);
      //console.log(layer);
      layer.addTo(this.map);
      this.addInteractivityToLayer(layer);
    },
    /**
     * Remove layers, if they exist
     */
    removeMapLayers() {
      if(this.map.getLayer(this.SC1.cityType)) {this.map.removeLayer(this.SC1.cityType)}
      if(this.map.getSource(this.SC1.cityType)) {this.map.removeLayer(this.SC1.cityType)}
      if(this.map.getLayer(this.SC2.cityType)) {this.map.removeLayer(this.SC2.cityType)}
      if(this.map.getSource(this.SC2.cityType)) {this.map.removeLayer(this.SC2.cityType)}
      if(this.map.getLayer(this.SC3.cityType)) {this.map.removeLayer(this.SC3.cityType)}
      if(this.map.getSource(this.SC3.cityType)) {this.map.removeLayer(this.SC3.cityType)}

      if(this.map.getLayer(this.scCombo.cityType)) {this.map.removeLayer(this.scCombo.cityType)}
      if(this.map.getSource(this.scCombo.cityType)) {this.map.removeLayer(this.scCombo.cityType)}
    },
    async layerMap() {
      this.removeMapLayers();
      if(this.filterByCityType1) {
        this.addLayer(this.SC1);
      }
      if(this.filterByCityType2) {
        this.addLayer(this.SC2);
      }
      if(this.filterByCityType3) {
        this.addLayer(this.SC3);
      }
      if(this.filterByCityType1 && this.filterByCityType2) {
        this.addComboLayer(this.scCombo);
      }
    },
    async initMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: `https://api.mapbox.com/styles/v1/${process.env.VUE_APP_MAPBOX_USERNAME}/${process.env.VUE_APP_MAPBOX_MAP_ID}?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`,
        center: [-5, 45],
        zoom: 2,
        attributionControl: false,
        dragPan: true,
        dragRotate: false
      });
      this.map.touchZoomRotate.disableRotation();
      // Is this needed?
      // this.map.on('load', () => {
      //   this.map.addSource('moroseblue', {
      //     type: 'vector',
      //     tiles: [`https://api.mapbox.com/styles/v1/${process.env.VUE_APP_CARTO_USERNAME}/${process.env.VUE_APP_MAPBOX_MAP_ID}.html?fresh=true$tile=view&access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`]
      //   })
      // })

    },
    // TODO: Replace with something more reliable
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Inspect the WebGL context of the map canvas, if it does not have the
     * OES_texture_float extension, route user to the error page.
     */
    testOES() {
      var testCanvas = document.getElementsByClassName("mapboxgl-canvas")[0];
      let gl = testCanvas.getContext("webgl");
      let test = null;
      try {
        test = gl.getExtension("OES_texture_float");
        if (!test) throw "OES_texture_float not found";
      } catch (error) {
        alert("Your browser/device does not support rendering this map!");
        this.$router.push({name: "Error"});
      }
    },
  },
  async mounted() {
    this.$gtm.trackView("Map", "/map");
    this.initMap()

    this.layerMap()

    //this.$root.$on('keyword search', this.layerMap);

    /**
     * Test that the browser and device has support for rendring the map canvas features.
     */
    this.$nextTick(() => {
      this.testOES();
    });
    
  },
}
</script>
<style lang="scss">

.main-map {
  margin-top: 10vh;
  #map {
    width: auto;
    height: 90vh;
  }

  div.filter-wrapper {
    position: fixed;
    bottom: 0px;
    width: 70vw;
    margin-left: 15vw;
  }
  @media only screen and (max-width: 495px) {
    div.filter-wrapper {
      width: 100vw;
      margin-left: unset;
    }
  }
}

// remove mapbox logo, for white listing
a.mapboxgl-ctrl-logo {
  display: none;
}

.mapboxgl-popup-tip {
  background-color: #2A72D8;
  border: 0.05rem solid transparent;
  padding-bottom: 4rem;
  height: 3rem;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  //height: 2.5rem;
  border-top-color: #2A72D8;
}

.mapboxgl-popup-content {
  position: absolute;
  top: -2.85rem;
  //left: 1.25rem;
  background-color: #2A72D8;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem 1rem 1rem 0rem;
  width: max-content;

  button.mapboxgl-popup-close-button {
    height: 1.35rem;
    right: unset;
    left: 0.125rem;
    top: 0.175rem;
    background-color: #fff;
    color: #2A72D8;
    border-radius: 1rem;
    text-transform: uppercase;
  }
}

.preview-wrapper {
  position: absolute;
  right: 2vw;
  top: 10vh;
  width: 35vw;
}
@media only screen and (min-width: 1290px) {
  .preview-wrapper {
    width: 40vw;
  }
}
@media only screen and (min-width: 1430px) {
  .preview-wrapper {
    width: 25vw;
  }
}

.mobile-preview-wrapper {
  position: absolute;
  top: 10vh;
}

.card-no-display {
  display: none;
}

.filter-toggle {
  position: absolute;
  height: 1.5rem;
  width: 10vw;
  margin-left: 45vw;
  background-color: #2A72D8;
  bottom: 0rem;
  .city-types-toggle {
    //width: 10rem;
    svg {
      height: 1.5rem;
      width: auto;
      //padding-top: 1rem;
      //padding-bottom: 1rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }
    // svg:hover {
    //   background-color: rgb(245, 245, 245);
    //   color: #717171;
    //   cursor: pointer;
    // }
  }
}

</style>
<template>
  <div class="mobile-preview">
    <div>
      <div 
        class="close-mobile-preview"
        @click="close()"
      >
        <b-icon icon="arrow-left-short"></b-icon> Go back to the map
      </div>
      <h1>{{ name }}</h1>
    </div>
    <div>
      <div class="city-image">
        <b-img :src="imgUrl ? imgUrl : placeHolderImg"></b-img>
      </div>
      <div class="city-description">
        {{ conformBlurb(blurb) }}
      </div>
      <div class="city-properties">
        <b-row class="city-type">
          <b-col
            v-if="cityType === 'SC1'"
          >
            <b-img :src="cityLogo"></b-img>
            Type 1: A city that attracts startups
          </b-col>
          <b-col
            v-if="cityType === 'SC2'"
          >
            <b-img :src="cityLogo"></b-img>
            Type 2: A city that acts like a startup
          </b-col>
          <b-col
            v-if="cityType === 'SC3'"
          >
            <b-img :src="cityLogo"></b-img>
            Type 3: A city that is a startup
          </b-col>
          <b-col
            v-if="cityType === 'combo'"
          >
            <b-img :src="cityLogo"></b-img>
            <p>Type 1 & 2: A city that attracts and acts like a startup</p>
          </b-col>
        </b-row>
        <b-row class="city-web-link">
          <b-col>
            <div v-if="cityType == 'SC1' || cityType == 'SC2' || cityType == 'combo'">
              <b-img :src="wikiLogo"></b-img>
              <a :href="webLink" target="__blank">Wikipedia</a>
            </div>
            <div v-else>
              <b-img :src="mouseLogo"></b-img>
              <a :href="webLink" target="__blank">Site</a>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import cityLogo from "../../assets/MiniCityLogo.svg";
import mouseLogo from "../../assets/Icon-feather-mouse-pointer.svg";
import wikiLogo from "../../assets/wikipedia-logo.svg";
import placeHolderImg from '../../assets/CityDefault.png';
export default {
  data() {
    return {
      cityLogo: cityLogo,
      mouseLogo: mouseLogo,
      wikiLogo: wikiLogo,
      placeHolderImg: placeHolderImg,
    }
  },
  computed: {
    ...mapGetters({
      name: "city/name",
      blurb: "city/blurb",
      imgUrl: "city/imgUrl",
      cityType: "city/cityType",
      webLink: "city/webLink",
      wikiLink: "city/wikiLink"
    }),
  },
  methods: {
    conformBlurb(text) {
      let newText = text.slice(1, -1);
      return newText.replace(/''/gm,`'`);
    },
    close() {
      const el = document.getElementById("mobile-preview-card");
      el.classList.add("slide-out-bottom");
      el.classList.add("slide-in-bottom");
    },
  }
}
</script>
<style lang="scss">
.mobile-preview {
  background-color: rgb(247, 247, 247);
  padding-right: 1rem;
  padding-left: 1rem;
  //margin-bottom: 3.5rem; // this is to not let the fixed filter control obscure the web link
  height: 90vh;
  //width: 100vw;
  div {
    div.close-mobile-preview {
      color: #9C9C9C;
      margin-bottom: 1rem;
      svg {
        color: #2A72D8;
      }
    }
    h1 {
      color: #2A72D8;
    }
    div.city-image {
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-bottom: 1.5rem;
      }
    }
    div.city-description {
      color: #9C9C9C;
      margin-bottom: 1rem;
      font-size: 13px;
    }
    div.city-properties {
      font-size: 13px;
      .row {
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        .col {
          margin-top: unset;
        }
      }
      div.city-type > div.col {
        color: #2A72D8;
        img {
          height: 1rem;
          width: auto;
          margin-right: 1rem;
        }
        p {
          font-size: 12px;
          display: inline;
        }
      }
      div.city-web-link {
        color: #2A72D8;
        margin-top: unset;
      }
      div.city-web-link > div.col {
        img {
          height: 1rem;
          width: auto;
          margin-right: 1rem;
        }
      }
    }
  }
}
</style>
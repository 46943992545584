<template>
  <div class="intro-component" v-bind:style="{'backgroundImage': 'url(' + backgroundImg + ')'}">
    <!-- <b-container id="stage-one">
      <div>
        <div class="balaji-twitter-card">
          <b-row class="balaji-twitter-row">
            <b-col cols="2"><b-img class="balaji-twitter-img" rounded="circle" :src="balajiTwitterPic"></b-img></b-col>
            <b-col cols="9" class="balaji-name-column">
              <label class="balaji-name">Balaji Srinivasan</label>
              <b-img class="twiter-verified-badge" :src="tvi"></b-img>
              <div>
                <p class="at-balaji-at">@</p><p class="at-balaji-balaji">balajis</p>
              </div>
            </b-col>
          </b-row>
          <div class="deffinition-head">
            What is a startup city? Three definitions.
          </div>
          <div class="deffinition-items">
            <p class="deffinition-item">1) A city where startups happen, like SF used to be</p>
            <p class="deffinition-item">2) A city that acts like a startup, like Miami, Singapore, Dubai</p>
            <p class="deffinition-item">3) A city that is a startup itself, culdesac.com and prospera.hn</p>
          </div>
        </div>
        <div class="after-twitter">
          <div>
            Author of <a href="https://balajis.com/the-network-state-book-a-crosspost/" target="__blank">The Network State</a>
          </div>
          <BIconArrowRightShort 
            v-on:click="stageTwo()"
            class="stage-two-toggle"
          />
        </div>
        
      </div>
    </b-container> -->
    <b-container>
      <div class="intro">
        <div class="scm-logo">
          <router-link to="/map">
            <b-img :src="scmLogo"></b-img>
          </router-link>
        </div>
        <b-row>
          <b-col>
            <div class="content-header">
              <h1>What is a Startup City?</h1>
            </div>
            <div class="content-paragraph">
              <p>
                In May of 2021, <b>Balaji Srinivasan</b>, author of <b><a href="https://www.amazon.com/Network-State-How-Start-Country-ebook/dp/B09VPKZR3G" target="__blank">The Network State</a></b>, described and divided startup cities into three groups.
                Balaji correctly pointed out that no worldwide or industry consensus exists on how to define a startup city, especially considering it can encompass many different focuses.
              </p>
              <p>
                As the startup city movement grows and new projects begin to emerge, the<b><a href="https://www.adrianoplegroup.com/" target="__blank"> Adrianople Group</a></b> has decided to create a tool that visualize this progress. This is how the <b>Startup Cities Map</b> was born.
              </p>
            </div>
            <div class="scm-map-link">
              <router-link to="/map">
                <span>
                  <p>Access the Startup Cities Map</p>
                  <b-icon icon="arrow-right"></b-icon>
                </span>
              </router-link>
            </div>
          </b-col>
          <b-col>
            <a href="https://twitter.com/balajis/status/1374247624988037120" target="__blank">
              <div class="balaji-twitter-card">
                <b-row class="balaji-twitter-row">
                  <b-col cols="2"><b-img class="balaji-twitter-img" rounded="circle" :src="balajiTwitterPic"></b-img></b-col>
                  <b-col cols="9" class="balaji-name-column">
                    <label class="balaji-name">Balaji Srinivasan</label>
                    <b-img class="twiter-verified-badge" :src="tvi"></b-img>
                    <div>
                      <p class="at-balaji-at">@</p><p class="at-balaji-balaji">balajis</p>
                    </div>
                  </b-col>
                </b-row>
                <div class="deffinition-head">
                  What is a startup city? Three definitions.
                </div>
                <div class="deffinition-items">
                  <p class="deffinition-item">1) A city where startups happen, like SF used to be</p>
                  <p class="deffinition-item">2) A city that acts like a startup, like Miami, Singapore, Dubai</p>
                  <p class="deffinition-item">3) A city that is a startup itself, culdesac.com and prospera.hn</p>
                </div>
                <div class="twitter-post-time">
                  <p>12:32 AM • Mar 23, 2021 • Twitter Web App</p>
                </div>
                <div class="balaji-network-state-link">
                  <p>Author of <a href="https://www.amazon.com/Network-State-How-Start-Country-ebook/dp/B09VPKZR3G" target="__blank">The Network State</a></p>
                </div>
              </div>
            </a>
          </b-col>
        </b-row>
        <div>

        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import tvi from "../assets/Twitter_Verified_Badge.svg";
import balajiTwitterPic from "../assets/balaji-twitter-img.jpg";
import backgroundImg from '../assets/IntroBackground.png';
import scmLogo from "../assets/logo-startupcitiesmap.svg"
//import { BIconArrowRightShort } from 'bootstrap-vue';

export default {
  components: {
    //BIconArrowRightShort
  },
  data() {
    return {
      backgroundImg: backgroundImg,
      balajiTwitterPic: balajiTwitterPic,
      tvi: tvi,
      scmLogo: scmLogo
    }
  },
  methods: {
    stageTwo() {
      setTimeout(() => {
        this.$router.push({ name: 'Select'});
      },
      550);
      const el2 = document.getElementById('stage-one');
      el2.classList.add('slide-out-left');
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.$gtm.trackView("Intro", "/");
  }
}
</script>
<style lang="scss">
.intro-component {
  background-color: rgba(0, 0, 0, 0);
  font-family: 'Cabin', sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  @media only screen and (max-width: 495px) {
    height: unset;
  }
  padding-bottom: 2rem;
  .intro {
    div.scm-logo > a > img {
      height: 3rem;
      width: auto;
      position: absolute;
      top: 1.2rem;
      left: 2rem;
    }
    div.row {
      div.col {

        div.content-header {
          padding-bottom: 1rem;
          h1 {
            color: #2A72D8;
          }
        }
        div.content-paragraph {
          color: rgb(123, 123, 123);
          justify-content: space-evenly;
          @media only screen and (max-width: 495px) {
            color: #2A72D8;
            text-align: justify;
            p {
              font-size: 0.9rem;
            }
          }
          p > b > a{
            text-decoration: none;
          }
        }
        div.scm-map-link {  
          a {
            padding: 0.5rem 0.5rem 0.5rem 0.5rem;
            border-radius: 16px;
            background-color: #2A72D8;
            color: #fff;
            span {
              white-space: nowrap;
              p {
                display: inline-block;
                padding-right: 0.9rem;
                padding-left: 0.5rem;
              }
              svg {
                display: inline-block;
                transform: scale(1.5);
                margin-right: 0.5rem
              }
            }
          }
        }
      }
      a {
        text-decoration: none;
        .balaji-twitter-card {
          cursor: pointer;
          color: #4b4b4b;
          border-radius: 10px;
          background-color: rgb(255, 255, 255);
          padding: 1rem;
          box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
          div.row.balaji-twitter-row{
            div.balaji-name-column {
              div {
                p.at-balaji-at {
                  display: inline;
                  font-family: sans-serif;
                }
                p.at-balaji-balaji {
                  display: inline;
                }
              }
            }
          }
          div.deffinition-items {
            p.deffinition-item {
              margin-bottom: unset;
            }
          }
          div.twitter-post-time {
            margin-top: 2rem;
            margin-left: 0.75rem;
            color: rgb(165, 165, 165);
            font-size: 0.75rem;
          }
          .balaji-network-state-link {
            //padding-top: 1rem;
            margin-left: 0.75rem;
          }
        }
      }
      @media only screen and (max-width: 800px) {
        .balaji-twitter-card {
          .balaji-twitter-row {
            padding-bottom: 1rem;
            margin-top: 1rem;
            margin-left: 0.25rem;
            .balaji-name-column {
              //padding-left: 1rem;
              p {
                //position: absolute;
                //top: 1.425rem;
              }
            }
            .balaji-twitter-img {
              height: 3.5rem;
              width: auto;
            }
            label.balaji-name {
              padding-left: 1rem;
              font-size: 16px;
              font-weight: 700;
              margin-bottom: unset;
              margin-top: 0.5rem;
              //padding-top: 1rem;
            }
            p {
              //padding-left: 1rem;
              color: #b0b0b0;
            }
            p.at-balaji-at {
              padding-left: 1rem;
            }
            .twiter-verified-badge {
              padding-left: 0.25rem;
              height: 0.75rem;
            }
          }
          .deffinition-head {
            padding-bottom: 1rem;
          }
          .deffinition-items, .deffinition-head {
            margin-left: 0.75rem;
          }
        }
        .after-twitter {
          margin-top: 2rem;
          text-align: center;
          color: #9C9C9C;
          svg.stage-two-toggle {
            margin-top: 2rem;
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 0);
            box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            border-radius: 2rem;
            color: #2A72D8;
            width: 2rem;
            height: auto;
          }
          svg.stage-two-toggle:hover {
            background-color: #2A72D8;
            color: rgb(247, 247, 247);
          }
        }
      }
      @media only screen and (min-width: 801px) {
        .balaji-twitter-card {
          position: absolute;
          //top: 100%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, 0);
          width: 480px;
          .balaji-twitter-row {
            padding-bottom: 1rem;
            margin-top: 1rem;
            margin-left: 0.25rem;
            .balaji-name-column {
              p {
                color: #b0b0b0;
              }
            }
            .balaji-twitter-img {
              height: 3.5rem;
              width: auto;
            }
            label.balaji-name {

            }
            p {

            }
            .twiter-verified-badge {
              padding-left: 0.25rem;
              height: 0.75rem;
            }
          }
          .deffinition-head {
            padding-bottom: 1rem;
          }
          .deffinition-items, .deffinition-head {
            margin-left: 0.75rem;
          }
        }
        .after-twitter {
          position: absolute;
          position: absolute;
          //top: 50%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%);
          top: 384px;


          margin-top: 2rem;
          text-align: center;
          color: #9C9C9C;
          svg.stage-two-toggle {
            margin-top: 2rem;
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 0);
            box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            border-radius: 2rem;
            color: #2A72D8;
            width: 2rem;
            height: auto;
          }
          svg.stage-two-toggle:hover {
            background-color: #2A72D8;
            color: rgb(247, 247, 247);
          }
        }
      }
    }
    @media only screen and (max-width: 495px) {
      div.scm-logo {
        text-align: center;
        padding-top: 0rem;
        padding-bottom: 2rem;
      }
      div.scm-logo > a > img {
        position: relative;
        height: 4rem;
        width: 15rem;
        margin-left: -19vw;
      }
      div.row > div.col {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      div.row {
        div.col:nth-child(2) {
          margin-top: 1rem;
        }
      }
    }
  }  


  // #stage-one {
  //   //height: 100vh;
  //   position: absolute;
  //   width: 60vw !important;
  //   top: 15vh;
  //   left: 20vw;
  //   @media only screen and (max-width: 610px) {
  //     //top: 20vh;
  //     width: 80vw !important;
  //     left: 10vw;
  //   }
  //   @media only screen and (max-width: 460px) {
  //     //top: 25vh;
  //     width: 90vw !important;
  //     left: 5vw;
  //   }
  //   // @media only screen and (min-width: 801px) {
  //   //   width: 90vw !important;
  //   // }
  // }
  // .at-balaji-at {
  //   display: inline;
  //   font-family: sans-serif;
  // }
  // .at-balaji-balaji {
  //   display: inline;
  // }
  // .balaji-twitter-card {
  //   color: #4b4b4b;
  // }
  // @media only screen and (max-width: 800px) {
  //   .balaji-twitter-card {
  //     background-color: #ffffff;
  //     padding: 1rem;
  //     box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  //     border-radius: 20px;
  //     .balaji-twitter-row {
  //       padding-bottom: 1rem;
  //       margin-top: 1rem;
  //       margin-left: 0.25rem;
  //       .balaji-name-column {
  //         //padding-left: 1rem;
  //         p {
  //           //position: absolute;
  //           //top: 1.425rem;
  //         }
  //       }
  //       .balaji-twitter-img {
  //         height: 3.5rem;
  //         width: auto;
  //       }
  //       label.balaji-name {
  //         padding-left: 1rem;
  //         font-size: 16px;
  //         font-weight: 700;
  //         margin-bottom: unset;
  //         margin-top: 0.5rem;
  //         //padding-top: 1rem;
  //       }
  //       p {
  //         //padding-left: 1rem;
  //         color: #b0b0b0;
  //       }
  //       p.at-balaji-at {
  //         padding-left: 1rem;
  //       }
  //       .twiter-verified-badge {
  //         padding-left: 0.25rem;
  //         height: 0.75rem;
  //       }
  //     }
  //     .deffinition-head {
  //       padding-bottom: 1rem;
  //     }
  //     .deffinition-items, .deffinition-head {
  //       margin-left: 0.75rem;
  //     }
  //   }
  //   .after-twitter {
  //     margin-top: 2rem;
  //     text-align: center;
  //     color: #9C9C9C;
  //     svg.stage-two-toggle {
  //       margin-top: 2rem;
  //       text-align: center;
  //       border: 1px solid rgba(0, 0, 0, 0);
  //       box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.25);
  //       cursor: pointer;
  //       border-radius: 2rem;
  //       color: #2A72D8;
  //       width: 2rem;
  //       height: auto;
  //     }
  //     svg.stage-two-toggle:hover {
  //       background-color: #2A72D8;
  //       color: rgb(247, 247, 247);
  //     }
  //   }
  // }
  // @media only screen and (min-width: 801px) {
  //   .balaji-twitter-card {
      
  //     position: absolute;
  //     //top: 100%;
  //     left: 50%;
  //     margin-right: -50%;
  //     transform: translate(-50%, 0);


  //     width: 480px;
  //     background-color: rgb(255, 255, 255);
  //     padding: 1rem;
  //     box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  //     border-radius: 20px;
  //     .balaji-twitter-row {
  //       padding-bottom: 1rem;
  //       margin-top: 1rem;
  //       margin-left: 0.25rem;
  //       .balaji-name-column {
  //         p {
  //           color: #b0b0b0;
  //         }
  //       }
  //       .balaji-twitter-img {
  //         height: 3.5rem;
  //         width: auto;
  //       }
  //       label.balaji-name {

  //       }
  //       p {

  //       }
  //       .twiter-verified-badge {
  //         padding-left: 0.25rem;
  //         height: 0.75rem;
  //       }
  //     }
  //     .deffinition-head {
  //       padding-bottom: 1rem;
  //     }
  //     .deffinition-items, .deffinition-head {
  //       margin-left: 0.75rem;
  //     }
  //   }
  //   .after-twitter {
  //     position: absolute;
  //     position: absolute;
  //     //top: 50%;
  //     left: 50%;
  //     margin-right: -50%;
  //     transform: translate(-50%, -50%);
  //     top: 384px;


  //     margin-top: 2rem;
  //     text-align: center;
  //     color: #9C9C9C;
  //     svg.stage-two-toggle {
  //       margin-top: 2rem;
  //       text-align: center;
  //       border: 1px solid rgba(0, 0, 0, 0);
  //       box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.25);
  //       cursor: pointer;
  //       border-radius: 2rem;
  //       color: #2A72D8;
  //       width: 2rem;
  //       height: auto;
  //     }
  //     svg.stage-two-toggle:hover {
  //       background-color: #2A72D8;
  //       color: rgb(247, 247, 247);
  //     }
  //   }
  // }
}
</style>
<template>
  <div class="not-found" v-bind:style="{'backgroundImage': 'url(' + backgroundImg + ')'}">
    <b-container>
      <b-row>
        <b-col>
          <div class="img">
            <b-img :src="notFound"></b-img>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="header">
          <h1>404 error</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="result">
          <h2>Page Not Found</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="explain">
          <p>Sorry but the requested page was not found</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="scm-map-link">
          <router-link to="Map">
            <span>Go back to the map</span>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import backgroundImg from '../assets/IntroBackground.png';
import notFound from "../assets/error-404-pin.svg";

export default {
  data() {
    return {
      backgroundImg: backgroundImg,
      notFound: notFound
    }
  }
}
</script>
<style lang="scss" scoped>
.not-found {
  background-color: rgba(0, 0, 0, 0);
  font-family: 'Cabin', sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;

  .container {
    div.row {
      padding-top: 1rem;
      padding-bottom: 1rem;
      .header {
        color: #2A72D8;
        h1 {
          font-size: 4.5rem;
        }
      }
      .result {
        color: #2A72D8;
      }
      .explain {
        color: #272727;
      }
      .scm-map-link {
        a {
            text-decoration: none;
            padding: 0.5rem 1.5rem 0.5rem 1.5rem;
            border-radius: 16px;
            background-color: #2A72D8;
            color: #fff;
            span {
              svg {
                display: inline-block;
                transform: scale(1.5);
                margin-right: 0.5rem
              }
            }
          }
      }
      div.col {
        text-align: center;
      }
    }
  }
}
</style>
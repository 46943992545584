<template>
  <div class="popup" ref="cityDeets">
    <div class="popup-top" v-bind:style="{ width: cityNameWidth + 'px' }">
      <p>{{ name }}</p>
      <BIconXCircle 
        class="close-mark rounded-circle p-2"
        v-on:click="closePopup()"
      />
      <b-icon-chevron-right
        v-if="isMobile()"
        class="action-mark rounded-circle p-2"
        v-on:click="action(name)"
      />
    </div>
    <div 
      v-if="cityType == 'SC1'" 
      class="city-type"
      v-bind:style="{ left: cityNameWidth + 'px' }"
    >
      <!-- Small text here, it can be a simple subtitle -->
      {{ country }}
    </div>
    <div 
      v-if="cityType == 'SC2'" 
      class="city-type"
      v-bind:style="{ left: cityNameWidth + 'px' }"
    >
      <!-- Small text here, it can be a simple subtitle -->
      {{ country }}
    </div>
    <div 
      v-if="cityType == 'SC3'" 
      class="city-type"
      v-bind:style="{ left: cityNameWidth + 'px' }"
    >
      <!-- Small text here, it can be a simple subtitle -->
      {{ country }}
    </div>
    <div 
      v-if="cityType == 'combo'" 
      class="city-type"
      v-bind:style="{ left: cityNameWidth + 'px' }"
    >
      <!-- Small text here, it can be a simple subtitle -->
      {{ country }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { BIconXCircle } from "bootstrap-vue";
export default {
  components: {
    BIconXCircle
  },
  computed: {
    ...mapGetters({
      set: "city/set",
      name: "city/name",
      country: "city/country",
      blurb: "city/blurb",
      imgUrl: "city/imgUrl",
      cityType: "city/cityType",
      close: "city/close",
      action: "city/action"
    }),
    cityNameWidth() { return this.name ? this.name.length * 13 : 0},
  },
  data() { 
    return {}
  },
  methods: {
    closePopup() {
      this.close();
    },
    // TODO: Replace with something more reliable
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    }
  },
  // async mounted() {
  //   console.log(this.name.length * 8);
  //   this.cityNameWidth = (this.name.length) * 8;
  // }
}
</script>
<style lang="scss" scoped>
.popup {
  position: relative;
  background-color: #2A72D8;
  color: #ffffff;
  font-size: 1.5rem;
  top: 2px;
  margin: auto;
  .popup-top {
    //margin-right: 1rem;
    //padding-bottom: 0.25rem;
    display: inline;
    p {
      display: inline-block;
      font-size: 18px;
      margin-bottom: 0.5rem;
      padding-right: 1rem;
    }
    svg {
      display: inline-block;
    }
  }
  .action-mark {
    //position: absolute;
    float: right;
    top: 0rem;
    right: 1.5rem;
    transform: scale(0.90);
    bottom: 0.1rem;
    cursor: pointer;
    padding: unset !important;
    background-color: #fff;
    color: #2A72D8;
  }
  .close-mark {
    //position: absolute;
    float: right;
    top: 0rem;
    right: -0.5rem;
    transform: scale(1);
    bottom: 0.1rem;
    cursor: pointer;
    padding: unset !important;
    background-color: #fff;
    color: #2A72D8;
  }
  .close-mark:hover {
    background-color: #2A72D8;
    color: #fff;
  }
  .city-type {
    //position: absolute;
    color: #fff;
    font-size: 0.75rem;
    width: 10rem;
    top: 2rem;
    padding-bottom: 0.75rem;
    line-height: 15px;
  }
}
</style>
import Vue from 'vue';
import Router from 'vue-router';

//import About from './views/About.vue';
import Intro from './views/Intro.vue';
import HowTo from './views/HowTo.vue';
import MainMap from './views/MainMap.vue';
//import SelectCity from './views/CitySelection.vue';
import NotFound from "./views/NotFound.vue";
import Error from "./views/Error.vue";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior: function(to) {
    //console.log('scrolling to', to);
    if (to.hash) {
        return {selector: to.hash}
        //Or for Vue 3:
        //return {el: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'Intro',
      component: Intro,
      meta: {
        title: "Startup Cities Map",
        description: "Startup Cities Map is a database of cities all around the world that are creating communities driven by technology, innovation and entrepreneurship"
      }
    },
    // {
    //   path: '/select',
    //   name: 'Select',
    //   component: SelectCity
    // },
    {
      path: '/map',
      name: 'Map',
      component: MainMap,
      meta: {
        title: "Startup Cities Map",
        description: "The Startup Cities map will show you where the most innovative cities are and present information about their type, function, and management."
      }
    },
    {
      path: '/about',
      name: 'About',
      component: () => import("./views/About.vue"),
      meta: {
        title: "Startup Cities Map - About",
        description: "The Startup Cities map was created to be the most updated database about innovative cities that exists."
      }
    },
    {
      path: '/howto',
      name: 'Howto',
      component: HowTo
    },
    {
      path: '/error',
      name: 'Error',
      component: Error
    },
    {
      path: "*",
      name: "NotFound",
      component: NotFound
    }
  ]
});

export default router;
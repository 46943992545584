<template>
  <b-navbar id="navbar" sticky>
    <div class="navbar-left">
      <b-navbar-brand href="/">
        <b-img class="logo" :src="logo"></b-img>
        <!-- <div class="logo-name">Startup Cities Map</div> -->
      </b-navbar-brand>
    </div>
    <div class="navbar-right" v-click-outside="onClickOutside">
      <!-- <Search v-if="isMapView" class="search"/> -->
      <div 
        class="map-icon-wrapper"
        v-if="!showMenuToggle"
        v-bind:style="{ backgroundColor: rightMenuActive ? '#2A72D8': '#fff'}"
      >
        <!-- <BIconMap 
          v-on:click="toggleMenu" 
          class="map-icon"
        /> -->
        <b-img
          v-show="!rightMenuActive"
          v-on:click="toggleMenu" 
          :src="mapIconDefault"
        ></b-img>
        <b-img
          v-show="rightMenuActive"
          v-on:click="toggleMenu" 
          :src="mapIconToggled"
        ></b-img>
        
      </div>
    </div>
    <transition name="slide-right">
      <RightMenu v-if="rightMenuActive"/>
    </transition>
  </b-navbar>
</template>
<script>
//import { BIconMap } from 'bootstrap-vue';
//import Search from '../NavBar/Search.vue';
import RightMenu from '../RightMenu/Menu.vue';
import Logo from '../../assets/logo-startupcitiesmap.svg';
import MapIconDefault from "../../assets/menu-icon-default.svg";
import MapIconToggle from "../../assets/menu-icon-toggle.svg";

export default {
  components: {
    //BIconMap,
    //Search,
    RightMenu
  },
  computed: {
    isMapView() {
      return this.$route.name == 'Map' ? true : false;
    },
    showMenuToggle() {
      //console.log(this.$route.name);
      return (this.$route.name == 'Intro' || this.$route.name == 'Select') ? true : false;
    }
  },
  data() {
    return {
      logo: Logo,
      rightMenuActive: false,
      mapIconDefault: MapIconDefault,
      mapIconToggled: MapIconToggle
    }
  },
  methods: {
    toggleMenu() {
      //console.log("toggleMenu", this.rightMenuActive)
      this.rightMenuActive = !this.rightMenuActive;
    },
    toggleMenuOff() {
      this.rightMenuActive = false;
    },
    onClickOutside() {
      //console.log("close menu")
      this.$root.$emit('close-menu');
    }
  },
  created() {
    this.$root.$on('close-menu', this.toggleMenuOff);
  },
  beforeDestroy() {
    this.$root.$off('close-menu', this.toggleMenuOff);
  }
}
</script>
<style lang="scss" scoped>
.navbar {
  position: fixed;
  width: 100%;
  justify-content: space-between;
  padding-right: 1rem;
  background-color: rgb(247, 247, 247);
  z-index: 1;
  height: 10vh;
  @media only screen and (max-width: 495px) {
    height: 10vh;
  }
  .navbar-left {
    .navbar-brand {
      font-family: aftika;
      font-weight: 700;
    }
    @media only screen and (max-width: 495px) {
      position: relative;
      width: 10rem;
      right: 1rem;
    }
    padding-left: 1rem;
    padding-right: 1rem;
    .logo {
      height: 3rem;
      width: auto;
    }
    .logo-name {
      position: relative;
      color: #2A72D8;
      padding-left: 0.75rem;
      //bottom: 0.15rem;
      display: inline-block;
      @media only screen and (max-width: 495px) {
        font-size: 0.75rem;
        top: -0.15rem;
      }
    }
  }
  .navbar-right {
    @media only screen and (max-width: 495px) {
      position: relative;
      top: -0.5rem;
      right: 1rem;
    }
    .search {
      display: inline-block;
      width: 10rem;
      @media only screen and (max-width: 495px) {
        width: 6rem;
        top: -1rem;
      }
      margin-right: 1rem;
    }
    .map-icon-wrapper {
      position: relative;
      display: inline-block;
      padding: 0.5rem 0.65rem;
      background-color: #fff;
      box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      border-radius: 1.5rem;
      // .map-icon {
      //   position: relative;
      //   width: auto;
      //   height: auto;
      //   background-color: #2A72D8;
      //   color: #fff;
      // }
      // @media only screen and (max-width: 495px) {
      //   float: right;
      //   left: 1rem;
      //   top: 0.55rem;
      // }
      img {
        width: 20px;
        height: 20px;
      }
    }
    // .map-icon-wrapper:hover {
    //   background-color: #2A72D8;
    //   color: #fff;
    //   .map-icon {
    //     background-color: #2A72D8;
    //     color: #fff;
    //   }
    // }
  }
  .right-menu-wrapper {
    background-color: #fff;
    position: absolute;
    height: 90vh;
    right: 0rem;
    top: 10vh;
    div.right-menu {
      z-index: inherit;
    }
  }
}
.slide-right-active, .slide-right-leave-active {
  //animation: open-width 1s;
  transition: all 0.5s;
  transform: translateX(0%);
  opacity: 1;
}
.slide-right-enter, .slide-right-leave-to {
  //animation: close-width 1s;
  transition: all 0.5s;
  transform: translateX(100%);
  opacity: 0;
}
</style>
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueGtm from "vue-gtm";
import VueMeta from 'vue-meta';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueGtm, {
  id: "GTM-T252CTL",
  queryParams: {},
  defer: false,
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  //ignoredViews: {},
  trackOnNextTick: false
});
Vue.use(VueMeta);

if (process.env.VUE_APP_ENV === "development") {
  // pass
} else {
  Vue.config.productionTip = false;
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
  Vue.config.production = true;
}

new Vue({
  store: store,
  router: router,
  render: h => h(App),
}).$mount('#app')

// adding directive
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
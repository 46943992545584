<template>
  <div class="feature-type">
    <div 
      class="feature"
      v-bind:style="{ backgroundColor: featureColor}"
    ></div>
    <p>{{ name }}</p>
  </div>
</template>
<script>
export default {
  props: {
      name: {
        required: true,
        type: String
      },
      featureColor: {
        required: true,
        type: String
      }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
  .feature-type {
    padding-left: 1rem;
    padding-right: 1rem;
    .feature {
      height: 1rem;
      width: 1rem;
      border-radius: 1rem;
    }
    p {
      margin-top: 1rem;
    }
  }
</style>
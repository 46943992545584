<template>
  <div
    class="city-types-ctl"
  >
    <transition name="fade">
      <div
        v-if="toggle" 
        class="city-types-toggle d-flex justify-content-center togl-1">
        <b-icon v-if="toggle" icon="chevron-compact-down" @click="setToggle()"/>
        <b-icon v-if="!toggle" icon="chevron-compact-up" @click="setToggle()"/>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="!toggle" 
        class="city-types-toggle d-flex justify-content-center togl-2">
        <b-icon v-if="toggle" icon="chevron-compact-down" @click="setToggle()"/>
        <b-icon v-if="!toggle" icon="chevron-compact-up" @click="setToggle()"/>
      </div>
    </transition>
    <transition name="slide-out">
      <div
        v-if="toggle"
        class="city-types-choices"
      >
        <!-- <div class="city-types-toggle d-flex justify-content-center">
          <b-icon v-if="toggle" icon="chevron-compact-down" @click="setToggle()"/>
          <b-icon v-if="!toggle" icon="chevron-compact-up" @click="setToggle()"/>
        </div> -->
        <b-row>
          <b-col cols="1"></b-col>
          <b-col sm>
            <b-form-checkbox
              class="check-box-city-type-1"
              v-model="cityType1.selected"
              switch
              inline
              size="lg"
            >
              <!-- {{ cityType1.name }} -->
            </b-form-checkbox>
            <div>
              <label>Type 1</label>
              <p>A city that attracts startups</p>
            </div>
          </b-col>
          <b-col sm>
            <b-form-checkbox
              class="check-box-city-type-2"
              v-model="cityType2.selected"
              switch
              inline
              size="lg"
            >
              <!-- {{ cityType2.name }} -->
            </b-form-checkbox>
            <div>
              <label>Type 2</label>
              <p>A city that acts like a startup</p>
            </div>
          </b-col>
          <b-col sm>
            <b-form-checkbox
              class="check-box-city-type-3"
              v-model="cityType3.selected"
              switch
              inline
              size="lg"
            >
              <!-- {{ cityType3.name }} -->
            </b-form-checkbox>
            <div>
              <label>Type 3</label>
              <p>A city that is a startup</p>
            </div>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
//import { BIconFilter } from 'bootstrap-vue';
export default {
  components: {
    //BIconFilter
  },
  computed: {
    ...mapGetters({
      cityTypes: 'filter/cityTypes',
      toggle: 'filter/toggle'
    }),
    cityType1: {
      get() { 
        return this.cityTypes.filter(e => e.cityType === 'Type 1')[0];
      },
      set() {
        this.$store.dispatch('filter/setCityTypes', { 
          cityType: 'Type 1',
          value: !this.cityType1 
        });
      }
    },
    cityType2: {
      get() { 
        return this.cityTypes.filter(e => e.cityType === 'Type 2')[0];
      },
      set() {
        this.$store.dispatch('filter/setCityTypes', { 
          cityType: 'Type 2',
          value: !this.cityType1 
        });
      }
    },
    cityType3: {
      get() { 
        return this.cityTypes.filter(e => e.cityType === 'Type 3')[0];
      },
      set() {
        this.$store.dispatch('filter/setCityTypes', { 
          cityType: 'Type 3',
          value: !this.cityType1 
        });
      }
    }
  },
  watch: {
    // toggle: function(val) {
    //   const el = document.getElementById("city-types-choices");
    //   //console.log('toggled', val, el);
    //   if (val) {
    //     el.classList.remove("slide-out-bottom");
    //     el.classList.remove("card-no-display");
    //     el.classList.add("slide-in-bottom");
    //   } else if (!val) {
    //     el.classList.add("slide-out-bottom");
    //     el.classList.add("slide-in-bottom");
    //   }
    // }
  },
  data() {
    return {
      filterCtlActive: false,
    }
  },
  methods: {
    setToggle() {
      this.$store.dispatch('filter/setFilterCtlToggle', !this.toggle);
    }
  }
}
</script>
<style lang="scss">
.city-types-ctl {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.0);
  //justify-content: center;
  border-radius: 1rem 1rem 0rem 0rem;

  .togl-2 {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .city-types-toggle {
    //width: 10rem;
    svg {
      height: 1.5rem;
      width: auto;
      //padding-top: 1rem;
      //padding-bottom: 1rem;
      padding-left: 3rem;
      padding-right: 3rem;
      border-radius: 16px 16px 0px 0px;
      background-color: #2A72D8;
    }
    // svg:hover {
    //   background-color: rgb(245, 245, 245);
    //   color: #717171;
    //   cursor: pointer;
    // }
  }
  .city-types-choices {
    background-color: #2A72D8;
    position: relative;
    padding-top: 1rem;
    //padding-bottom: 1.25rem;
    font-size: 12px;
    line-height: 24px;
    z-index: 0;
    border-radius: 1rem 1rem 0rem 0rem;
    @media only screen and (max-width: 495px) {
      div.row > * {
        flex-shrink: unset;
      }
    }
    div.row {
      div.col-sm {
        display: flex !important;
        padding-right: unset;
        padding-left: unset;
        div {
          label {
            font-size: 16px;
            line-height: 1.75rem;
          }
        }
        label {
          margin-bottom: unset;
        }
        label, p {
          margin-left: 1rem;
        }
        .custom-switch {
          margin-right: unset;
          label.custom-control-label{
            white-space: nowrap;
          }
        }
        @media only screen and (max-width: 495px) {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          margin-left: 12.5vw;
          margin-right: 12.5vw;
          label {
            font-size: 1.75rem;
            line-height: 1.75rem;
            margin-bottom: unset;
          }
          p {
            display: block;
            white-space: pre;
          }
        }
      }
    }
    .check-box-city-type-1 {
      .custom-control-input:checked~.custom-control-label::before {
        border-color: #4FCCF2;
        background-color: #4FCCF2;
        margin-right: unset;
      }
    }
    .check-box-city-type-2 {
      .custom-control-input:checked~.custom-control-label::before {
        border-color: #D4A6D8;
        background-color: #D4A6D8;
        margin-right: unset;
      }
    }
    .check-box-city-type-3 {
      .custom-control-input:checked~.custom-control-label::before {
        border-color: #81D17A;
        background-color: #81D17A;
        margin-right: unset;
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.5s;
  opacity: 1;

}
.fade-enter, .fade-leave-to {
  //transform: translateY(100%);
  opacity: 0;
}

.slide-out-enter-active, .slide-out-leave-active {
  transition: all 0.5s;
  //transform: translateY(0%);
  opacity: 1
}
.slide-out-enter, .slide-out-leave-to {
  //transform: translateY(100%);
  opacity: 0;
}

</style>